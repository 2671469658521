

// export const baseurl='http://localhost:5000'
// export const baseurl='https://brokerbulletinsbackend-production.up.railway.app'


// export const baseurl='https://brokerbulletinsbackend-production-1599.up.railway.app'


export const baseurl='https://brokerbulletins-54871d77d1d8.herokuapp.com'

